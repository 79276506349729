import { injectVariables } from "domains/utils/wordings";
import WordingProvider from "../../clients/wording/WordingProvider";

interface IProps {
  textKey: string;
  variables?: Record<string, string>;
}

export default function ({ textKey, variables }: IProps) {
  const translations = WordingProvider.shared.translations;
  const textToDisplay = translations[textKey] || textKey;
  const textWithVariables = variables
    ? injectVariables({
        rawString: textToDisplay,
        variables,
      })
    : textToDisplay;
  return <>{textWithVariables}</>;
}
