import translations from "./translations.json";

export default class WordingProvider {
  static shared = new WordingProvider();
  translations: Record<string, string> = translations;

  getText(key: string) {
    if (!this.translations)
      throw new Error("Tried to get the cache, but none is available");

    return this.translations[key] || key;
  }
}
