import WordingProvider from "../../clients/wording/WordingProvider.ts";

export function getFirstAuthEmailBody(variables: {
  FIRST_NAME: string;
  LAST_NAME: string;
  CODE: string;
}) {
  return injectVariables({
    rawString: getText("first_auth_email_body"),
    variables,
  });
}
export function getFirstAuthEmailSubject(variables: { FIRST_NAME: string }) {
  return injectVariables({
    rawString: getText("first_auth_email_subject"),
    variables,
  });
}
export function injectVariables({
  rawString,
  variables,
}: {
  rawString: string;
  variables: Record<string, string>;
}) {
  return rawString.replace(
    /\${(.*?)}/g,
    (_, key: keyof typeof variables) => variables[key] ?? `$\{${key}}`
  );
}

export function getText(key: string) {
  return WordingProvider.shared.getText(key);
}

export function getCollaboratorBankDetailsRequestNotificationSubject(
  variables: Record<string, string>
) {
  return injectVariables({
    rawString: getText(
      "bank_details_data_available_collaborator_email_subject"
    ),
    variables,
  });
}

export function getCollaboratorBankDetailsRequestNotificationBody(
  variables: Record<string, string>
) {
  return injectVariables({
    rawString: getText("bank_details_data_available_collaborator_email_body"),
    variables,
  });
}
